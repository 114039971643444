import React from 'react';
import './../assets/css/Footer.css';

const Footer = () => {
  const handleScroll = (targetClass) => {
    const targetSection = document.querySelector(targetClass);
    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <footer className="footer">
      <div className="footer-socials">
       
      <a href="https://www.instagram.com/tribal_camping?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="  
   aria-label="Instagram" target="_blank" rel="noopener noreferrer">
  <i className="fab fa-instagram"></i>
</a>

       
      </div>
      <div className="footer-links">
        <a href="#about-camp" onClick={() => handleScroll('.hero')}>Home</a>
        <a href="#places" onClick={() => handleScroll('.wildlife-section-hero')}>Places</a>
        <a href="#download" onClick={() => handleScroll('.adventure-section')}>Download</a>
        <a href="#faqs" onClick={() => handleScroll('.faq-section1')}>FAQs</a>
        <a href="#the-team" onClick={() => handleScroll('.explore-section')}>Contact</a>
      </div>
      <div className="footer-brand">
        <p><strong>Tribal Camping</strong></p>
      </div>
    </footer>
  );
};

export default Footer;
