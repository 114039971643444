import React from 'react';
import './../assets/css/Hero.css';

const Hero = () => {
  
  
  const handleScroll = () => {
    const targetSection = document.querySelector('.wildlife-section-hero');
    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };


  return (
    <section className="hero">
      <div className="hero-text-wrapper">
        <h1 className="hero-title afacad-flux-hero">Your Adventure Begins Here</h1>
        <p className="hero-small-text afacad-flux-hero">Discover the Great Outdoors</p>
      </div>
  
      <div className="hero-footer">
        <button className="hero-button afacad-flux-hero" onClick={handleScroll}>Check some spots</button>
        <p className="hero-price"> </p>
      </div>
    </section>
  );
};

export default Hero;
