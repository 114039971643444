import React, { useState, useEffect, useRef } from 'react';
import './../../assets/css/WildlifeSection.css';
import kayakTourImg from './../../images/europeASAf.png'; // Placeholder image import

// Properly import images for each location
import place_one_image_one from './../../images/place1-1.png';
import place_one_image_two from './../../images/place1-2.png';
import place_one_image_three from './../../images/place1-3.png';
import place_two_image_one from './../../images/place2-1.png';
import place_two_image_two from './../../images/place2-2.png';
import place_two_image_three from './../../images/place2-3.png';
import place_three_image_one from './../../images/place3-1.png';
import place_three_image_two from './../../images/place3-2.png';
import place_four_image_one from './../../images/place4-1.png'; // Reused image
import place_four_image_two from './../../images/place4-2.png';
import place_four_image_three from './../../images/place4-3.png';
import place_five_image_one from './../../images/place5-1.png';
import place_five_image_two from './../../images/place5-2.png';

const WildlifeSection = () => {

  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [mapVisible, setMapVisible] = useState(false); // State for map visibility
  const [infoVisible, setInfoVisible] = useState(false); // State for info section visibility

  const mapSectionRef = useRef(null);
  const infoSectionRef = useRef(null);

  // Array of map locations with coordinates for zoom and pin position
  const locations = [
    {
      id: 1,
      name: 'Ireland',
      description: 'Near County Wicklow, Leinster, Ireland. These trails offer stunning landscapes for hikers and are easily accessible by car. Gentle streams, forests, and scenic spots provide a mix of relaxation and adventure, with activities like canoeing and rafting.',
      zoomX: '-7%', 
      zoomY: '-7%', 
      pinX: '38%',  
      pinY: '34%',  
      link: '/bois-de-liesse',
      images: [place_one_image_one, place_one_image_two, place_one_image_three],
    },
    {
      id: 2,
      name: 'Serbia ',
      description: 'Near Zlatibor Administrative District, Central Serbia, Serbia. These trails, perfect for hiking and accessible by car, offer stunning landscapes and varied terrains. With lakes, rivers, and forests, visitors can enjoy scenic views, challenging hikes, and activities like fishing and rafting, all while benefiting from nearby water facilities for convenience.',
      zoomX: '-36%', 
      zoomY: '-35%', 
      pinX: '62.5%',   
      pinY: '64%',   
      link: '/bois-de-liesse',
      images: [place_two_image_one, place_two_image_two, place_two_image_three],
    },
    {
      id: 3,
      name: 'Spain',
      description: 'Near Jaén, Andalusia, Spain. These trails offer scenic hikes and are easily accessible by car. Dense forests, waterfalls, and caves provide adventure, while rivers are perfect for canoeing, with water facilities nearby for convenience.',
      zoomX: '-2%', 
      zoomY: '-45%', 
      pinX: '32%',   
      pinY: '70%',   
      link: '/bois-de-liesse',
      images: [place_three_image_one, place_three_image_two],
    },
    {
      id: 4,
      name: 'Switzerland',
      description: 'Near District d\'Aigle, Vaud, Switzerland. These trails offer hiking and scenic views, accessible by car. Cliffs, lakes, and streams provide adventure, while reserves and serene lakes are perfect for camping and water activities.',
      zoomX: '-20%', 
      zoomY: '-30%', 
      pinX: '50%',   
      pinY: '56%',   
      link: '/bois-de-liesse',
      images: [place_four_image_one, place_four_image_two, place_four_image_three],
    },
    {
      id: 5,
      name: 'Italy',
      description: 'Near Messina, Sicily, Italy. These trails offer diverse terrains and stunning landscapes, perfect for hiking enthusiasts seeking adventure. Easily accessible by car, the area provides ample water sources and utilities, ensuring convenience and hydration for campers and families alike.',
      zoomX: '-26%', 
      zoomY: '-50%', 
      pinX: '54%',   
      pinY: '79%',   
      link: '/bois-de-liesse',
      images: [place_five_image_one, place_five_image_two],
    }
  ];

  const [currentLocationIndex, setCurrentLocationIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const currentLocation = locations[currentLocationIndex];

  // Handle previous and next location
  const handlePrevLocationClick = () => {
    setCurrentLocationIndex(currentLocationIndex === 0 ? locations.length - 1 : currentLocationIndex - 1);
    setCurrentImageIndex(0); // Reset image index on location change
  };

  const handleNextLocationClick = () => {
    setCurrentLocationIndex(currentLocationIndex === locations.length - 1 ? 0 : currentLocationIndex + 1);
    setCurrentImageIndex(0); // Reset image index on location change
  };

  const handlePrevImageClick = () => {
    setCurrentImageIndex(currentImageIndex === 0 ? currentLocation.images.length - 1 : currentImageIndex - 1);
  };

  const handleNextImageClick = () => {
    setCurrentImageIndex(currentImageIndex === currentLocation.images.length - 1 ? 0 : currentImageIndex + 1);
  };

  // IntersectionObserver setup
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1, // Trigger when 10% of the section is visible
    };

    const mapObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setMapVisible(true);
        }
      });
    }, observerOptions);

    const infoObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInfoVisible(true);
        }
      });
    }, observerOptions);

    if (mapSectionRef.current) {
      mapObserver.observe(mapSectionRef.current);
    }

    if (infoSectionRef.current) {
      infoObserver.observe(infoSectionRef.current);
    }

    return () => {
      if (mapSectionRef.current) {
        mapObserver.unobserve(mapSectionRef.current);
      }
      if (infoSectionRef.current) {
        infoObserver.unobserve(infoSectionRef.current);
      }
    };
  }, []);

  return (
    <section className="wildlife-section-hero afacad-flux-wild">
      <div
        className={`wildlife-map-section ${mapVisible ? 'animate-map' : ''}`}
        ref={mapSectionRef}
      >
        <div className="map-container ">
          <div className="map-navigation-panel">
            <span className="current-location">
              {currentLocation.id} / {locations.length}
            </span>
            <p className="current-location ">{currentLocation.name}</p>
            <div className="navigation-arrows">
              <span className="left-arrow" onClick={handlePrevLocationClick}>&#8592;</span>
              <span className="right-arrow" onClick={handleNextLocationClick}>&#8594;</span>
            </div>
          </div>

          <div 
            className="map-image" 
            style={{
              transform: `scale(2) translate(${currentLocation.zoomX}, ${currentLocation.zoomY})`
            }}>
            <img src={kayakTourImg} alt="Map" className="map-square" />

            <a
              href={currentLocation.link}
              className="map-pin"
              style={{ top: currentLocation.pinY, left: currentLocation.pinX }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="pin-icon"></div>
              <span className="pin-label">{currentLocation.name}</span>
            </a>
          </div>
        </div>
      </div>

      <div
        className={`wildlife-info-section ${infoVisible ? 'animate-info' : ''}`}
        ref={infoSectionRef}
      >
        <h2>{currentLocation.name}</h2>
        <p className="wildlife-info-section-paragraph" >{currentLocation.description}</p>

        <div className="wildlife-images">
          <div className="desktop-images">
            {currentLocation.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Camping Spot ${index + 1}`}
                onClick={() => setFullscreenImage(image)}  // Dodaj onClick event za otvaranje slike preko celog ekrana
                style={{ cursor: 'pointer', width:300, height:300 }}  // Dodaj stil za pokazivač da naglasi da je slika klikabilna
              />
            ))}
          </div>

          {fullscreenImage && (
            <div className="fullscreen-overlay" onClick={() => setFullscreenImage(null)}>
              
              <img src={fullscreenImage} alt="Full screen view" />
            </div>
          )}

          <div className="mobile-images">
            <div className="image-navigation">
              <span className="left-arrow-r" onClick={handlePrevImageClick}>&#8592;</span>
              <img src={currentLocation.images[currentImageIndex]} alt={`Camping Spot ${currentImageIndex + 1}`} />
              <span className="right-arrow-r" onClick={handleNextImageClick}>&#8594;</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WildlifeSection;
