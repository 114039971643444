import React from 'react';
import './../../assets/css/StatsSection.css'; // Custom CSS for the component

const StatsSection = () => {
  return (
    <section className="stats-section afacad-flux-wild">
      <div className="overlay">
        <div className="text-overlay">
          <p>
            With each visit, you contribute to the ongoing story of conservation, ensuring that the magic of our camping spots endures for generations to come.
          </p>
        </div>
        <div className="stats">
          <div><span>62,394</span> Mapped Spots</div>
          <div><span>23</span> Mapped Countries</div>
          <div><span>37</span> Search Categories</div>
        </div>
      </div>
    </section>
  );
};

export default StatsSection;

