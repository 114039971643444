import React, { useState } from 'react';
import './../../assets/css/ThingsToDo.css'; // If you use external CSS
import faza1 from './../../images/faza1.jpg'; // Placeholder image import
import faza2 from './../../images/faza2.jpeg'; // Placeholder image import
import faza3 from './../../images/faza3.png'; // Placeholder image import

const ThingsToDo = () => {
  const [showDetails, setShowDetails] = useState(null);

  const things = [
    {
      title: 'Search for spots near you',
      description:
        'Tribal gives you best hidden locations near you. For now we only support Europe, but we will start mapping US and Australian regions soon. Wherever you are, you can pretty much find a great spot within an hours drive.',
      details:
        'We allow you to pick and choose. You like waterfalls, heres 10 campsites with waterfalls, want it to also have a bench and be near the lake, heres 5 more.',
      price: '$599 / day',
      rating: '★★★★★',
      views: '1.8k views',
      likes: '592 likes',
      imageUrl: faza1,
    },
    {
      title: 'Search for staff you want',
      description:
        'We allow you to pick and choose. You like waterfalls, heres 10 campsites with waterfalls, want it to also have a bench and be near the lake, heres 5 more... There are a bunch of more stuff you can search by like rivers, hiking locations, bioms (forest, grass field, mountain...), amenities (firepits, benches, wild water fountains, etc.), caves and so much more.',
      details:
        ' Connect with the right professionals in no time. Whether you need temporary support, project-based experts, or long-term team members, our advanced search platform lets you filter through qualified staff according to your unique needs. Get instant access to skilled individuals, ensuring you work with the best talent available and achieve the results you want without hassle.',
      price: '$399 / group',
      rating: '★★★★★',
      views: '1.7k views',
      likes: '473 likes',
      imageUrl: faza2,
    },
    {
      title: 'Know everything about the spot',
      description:
        'Our app allows you to find everything about the spot there is to know, before setting a foot there. We show you images and their locations, nearby wildlife, features and where they are and amenities. Our camping talored weather forecast will help you pick the best day to visit certain campsite.',
      details:
        'Gain in-depth knowledge about every location before you visit. From comprehensive reviews and detailed facility information to local insights and recommendations, we provide everything you need to make informed decisions. Whether you\'re visiting for the first time or returning, you\'ll always have the latest and most accurate information to enhance your experience and make your time there unforgettable.',
      price: '$99 / person',
      rating: '★★★★★',
      views: '1.2k views',
      likes: '352 likes',
      imageUrl: faza3,
    },
  ];

  const toggleDetails = (index) => {
    setShowDetails(index === showDetails ? null : index);
  };

  return (
    <div className="things-to-do afacad-flux-wild">
    <h1 style={{fontSize:'3em'}}>Top Things to Do With Tribal Camping App</h1>
    <div className="things-grid">
      {things.map((thing, index) => (
        <div className={`thing-item ${index % 2 === 0 ? 'right-text' : 'left-text'}`} key={index}>
          <img src={thing.imageUrl} alt={thing.title} />
          <div className="thing-details">
            <h3>{thing.title}</h3>
            <p>{thing.description}</p>
           
          </div>
        </div>
      ))}
    </div>
  </div>
  
  );
};

export default ThingsToDo;
