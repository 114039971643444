import React, { useEffect, useRef } from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import WildlifeSection from './components/Sections/WildlifeSection';
import AdventureSection from './components/Sections/AdventureSection';
import StatsSection from './components/Sections/StatsSection';
import ExploreSection from './components/Sections/ExploreSection';
import ThingsToDo from './components/Sections/ThingsToDo';
import Footer from './components/Footer';
import ForestTransition from './components/Sections/ForestTransition';
import FAQSection from './components/Sections/FAQSection';
import DownloadSection from './components/Sections/DownloadSection';
import './App.css';

function App() {
  const sectionRefs = useRef([]);

  // Intersection observer callback
  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('visible');
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.2, // Section appears when 20% is visible
    });

    // Observe each section
    sectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      // Cleanup observer on unmount
      sectionRefs.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <div className="App">
      <Navbar />
        <Hero />
        <WildlifeSection />
        <ForestTransition />
        <AdventureSection />
        <StatsSection />
      <div ref={(el) => (sectionRefs.current[5] = el)} className="fade-in-section">
        <ThingsToDo />
      </div>
      <div ref={(el) => (sectionRefs.current[6] = el)} className="fade-in-section">
        <DownloadSection />
      </div>
      <div ref={(el) => (sectionRefs.current[7] = el)} className="fade-in-section">
        <FAQSection />
      </div>
      <div ref={(el) => (sectionRefs.current[8] = el)} className="fade-in-section">
        <ExploreSection />
      </div>
      <Footer />
    </div>
  );
}

export default App;
