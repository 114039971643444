import React from 'react';
import './../../assets/css/ForestTransition.css'; // Kreiramo zasebni CSS fajl za stilizaciju

const ForestTransition = () => {
  return (
    <div className="forest-transition">
      <div className="tree-line"></div>
    </div>
  );
};

export default ForestTransition;
