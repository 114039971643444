import React from 'react';
import './../../assets/css/ExploreSection.css';
import FAQSection from './FAQSection';

const ExploreSection = () => {
  return (
    <section className="explore-section afacad-flux-wild">
      <div className="explore-content">
        <h2>Your Exploration Starts Here</h2>
        <p>
          Got questions or stories to share? <br></br>
          Contact us and let's make your next <br></br>
          adventure even better!
        </p>
        <hr className="explore-divider" /> {/* Tanka linija između explore-content i explore-info */}
        <div className="explore-info">
          <span>tribalcamping@gmail.com</span>
        </div>
      </div>
    </section>
  );
};

export default ExploreSection;
